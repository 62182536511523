
*{
    font-family: 'Montserrat', sans-serif;
}

.sidebar{
    background-color: white;
    width: 16%;
    position: fixed;
    height: 100%;
    padding: 10px;
}
.sidebar-logo img{
    width: 70%;
    margin-top: 20px;
}

.menu{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    border-top: solid #f9f9f9 1px;
    margin-top: 20px;
}

.menu-items{
    margin-left: 15px;
}
.menu-items img{
    width: 14px;
    margin-right: 10px;
    cursor: pointer;
}
.menu-items a{
    color: #1f1f1f;
    font-weight: 500;
    text-decoration: none;
}
.menu-items a{
    display: flex;
    flex-direction: row;
}
.menu-items p{
    margin-top: 16px;
}
.menu-items a{
    margin-bottom: -5px;
}
.sidebar-bottom{
    position: absolute;
    bottom: 0;
    padding: 0px;
    float: left;
}
.sidebar-profile{
    padding: 15px;
    background-color: #F0F6FD;
    width: 96%;
    margin-bottom: 10px;
    border-radius: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.sidebar-profile img{
    width: 40%;
    margin-top: -40px;
    margin-left: 30%;
}
.sidebar-profile p{
    font-weight: 600;
    text-align: center;
    padding: 10px;
}
.sidebar-profile button{
    background:red;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    padding: 4px;
    margin-top: -10px;
}
.sidebar-top{
    position: fixed;
    width: 85%;
    margin-left: 15%;
    padding: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 63px;
    overflow: hidden;
    z-index: 99;
}
.sidebar-top-left{
    width: 80%;
    padding-left: 20px;
    border-right: solid #f0f0f0 1px;
    margin-right: 40px;
}
.sidebar-top-right{
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.container-right{
    position: relative;
    width: 83%;
    float: left;
    margin-left: 16.2%;
    padding: 20px;
    margin-top: 66px;
    background-color: white;
    border-radius: 0px;
   
}

.sidebar-top-right img{
    width: 20px;
    margin-top: 10px;
    float: left;
    cursor: pointer;
}
.business-name{
    float: left;
    margin-left: 10px;
    padding: 4px;
    margin-top: 5px;
    cursor: pointer;
    height: 100px;
    display: flex;flex-direction: row;
}
.business-name p{
    font-size: 16px;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 30px;
}
#user{
    width: 30px;
    height: 30px;
    margin-top: -4px;
    background-color: #F0F6FD;
    border-radius: 100px;
    margin-left: 5px;
}

.sidebar-top-left p{
    margin-left: 5px;
    margin-top: 5px;
    font-weight: 600;
    font-size: 22px;
}

.menu-items.active {
    background: #f9f9f9;
    padding-left: 15px;
    margin-left: 0px;
    padding-top: 0px;
    border-radius: 8px;
    
    margin-top: 0px;
}
.menu-items.active a{
    color: black!important;
    font-weight: 600;
}
.menu-items a{
    margin-top: -5px;
}

.create-invoice {
    float: left;
    width: 60%;
    padding: 10px;
}

.create-invoice label{
    width: 30%;
    float: left;
    margin-right: 20px;
    border-right: dashed #f0f0f0 2px;
}
.create-invoice select,.create-invoice input {
    width: 60%;
    float: left;
    margin-top: -5px;
    border: solid #0068ff 1px!important;
}





.container-right-outside {
    position: relative;
    width: 100%;
    float: left;
    margin-left: 0%;
    padding: 20px;
    margin-top: 0;
    background-color: white;
    border-radius: 0px;
  }