*{
  font-family: 'Montserrat', sans-serif;
}
.parent .Floro-container {
  width: 850px;
  height: 1200px;
  background-color: #E6E6E6;
  margin-left: 300px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding-left: 10px;
}

.parent .border-div {
  position: relative;
  left: 300px;

  height: 100vvh;
  width: 850px;
  border: 2px solid black;
}

.parent .Floro-container-one {
  border: 0px solid black;
  width: 800px;
  margin: auto;
  padding: 20px;

}

.parent .Floro-container-one h2 {
  text-align: center;
  margin-top: 5px;
  font-size: 20px;
}

.parent .container-one {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.parent .container-one-left img {
  margin: auto;
  height: 70px;
  object-fit: cover;
}
.parent .last-container-right-po {
  
  font-size: 12px;
}

#vendorDetails{
  font-size: 15px !important;
}

.parent .container-one-right h4 {
  font-weight: 500;
  font-size: 14px;
}

.parent .container-one-right h5 {
  margin-left: 130px;
  color: darkblue;
  font-size: 14px;
  font-weight: 700;
}

.parent .hr {
  width: 100%px;
  background-color: black;
  margin-top: 30px;
}

.parent .bill-para {
  margin-top: 30px;
  margin-left: 5px;
  font-size: 14px;
}

.parent .floro-table {
  margin-top: 12px;
  margin-left: 5px;
}

.parent .container-two {
  display: flex;
  flex-direction: row;

}

.parent .container-two-right {
  position: relative;
  right: 20px;
}

.parent .container-two-left h1 {
  margin-left: 5px;
  margin-top: 10px;
}

.parent .container-two-left h6 {
  font-size: 14px;
}

.parent .container-two-right h6 {
  font-size: 14px;
}

.parent .heading3 {
  margin-top: 15px;
  margin-left: 500px;
  font-weight: 500;
  font-size: 14px;
}



.parent table th {
  font-size: 13px;
  font-family: sans-serif;

}

.parent table th td {
  border-collapse: collapse;


}

.parent .margin-terms {
  margin-top: 650px;
}
.parent .Floro-container-one {
  width: 800px;
  margin: auto;
  background: white;
  padding: 20px;

}
#headingTra{
  background-color: #f6f6f6;
  width: 80%;
  padding: 8px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 5px;
}
.inner-ring {
 
  padding-left: 15px;
}
.parent.Floro-container.lastc {
  font-size: 10px;
}
.tax{
  float: right;
  width: 100%;
  
}

#fixwidth {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.personal{
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: right;
  padding-left: 10px;
  margin-right: 10px;
  float: right;
}

#vendor{
  font-size: 16px;
  font-weight: 600;
}
#customerDetails{
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom:20px;
  float: left;
  width: 100%;
}
.container-four-left.challanTableOne tbody th {
  width: 50%;
}
table.purchasetable td,table.purchasetable th {
  border: none !important;
  border-bottom: dashed black 1px !important;
}
